<template>
  <RedocWrapper :specOrSpecUrl="spec" :scrollTop="scrollTop" />
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue'

import yamljs from 'yamljs'
import { notification as antNotification } from 'ant-design-vue'

import RedocWrapper from '@/components/RedocWrapper'

export default {
  name: 'RedocPreviewer',
  components: {
    RedocWrapper,
  },
  setup() {
    const spec = reactive({})
    const lastEditorValue = ref('')
    const scrollTop = ref()

    const changeSpec = async ({ openApiEditorValue, scrollToTop }) => {
      const currentScrollTop = document.documentElement.scrollTop
      scrollTop.value = scrollToTop ? 0 : currentScrollTop

      try {
        Object.assign(spec, yamljs.parse(openApiEditorValue))
      } catch (error) {
        const { message, parsedLine, snippet } = error
        antNotification.error({
          message: `Error: ${message}`,
          description: `${parsedLine ? `#${parsedLine}` : ''}${snippet ? ` ${snippet}` : ''}`,
          duration: 5,
        })
      }

      lastEditorValue.value = openApiEditorValue
    }

    const handleMessageFromEditor = ({ data }) => { if (data.openApiEditorValue) changeSpec(data) }

    onMounted(async () => {
      await nextTick(() => {
        window.addEventListener('message', handleMessageFromEditor)
        window.parent.postMessage({ isPreviewerReady: true }, '*')
      })
    })

    onUnmounted(() => {
      window.removeEventListener('message', handleMessageFromEditor)
    })

    return {
      spec,
      scrollTop,
    }
  },
}
</script>
